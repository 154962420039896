import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#74918f',
      contrast: '#35271e',
      secondary: '#38322f',
      grey: '#EEEEEE',
      light: '#FFFFFF'
    },

    text: {
      main: '#35271e',
      contrast: '#74918f',
      secondary: '#38322f',
      light: '#FFFFFF'
    },
  },

  font: {
    family: {
      main: 'Roboto',
      secondary: 'Merriweather',
      logo: 'Merriweather Sans'
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '15px',
      m: '16px',
      xm: '20px',
      22: '22px',
      l: '24px',
      27: '27px',
      xl: '30px',
      xsl: '33px',
      xxl: '45px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 700,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.main};
  }

  h2 {
    font-family: ${props => props.theme.font.family.logo};
    font-weight: ${props => props.theme.font.weight.l};
    color: ${props => props.theme.color.face.secondary};
    font-size: ${props => props.theme.font.size.xsl};
  }

  ${props => Object.keys(props.theme.color.face).map(key => {
    return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
  })}

  ${props => Object.keys(props.theme.color.text).map(key => {
    return `.color-${key} { color: ${props.theme.color.text[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.family).map(key => {
    return `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.size).map(key => {
    return `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`
  })}

  ${props => Object.keys(props.theme.font.weight).map(key => {
    return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
  })}

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme